import React from 'react'

import { ErrorBoundaryUI } from '@element/ErrorBoundary'
import PageNotFoundIcon from '@asset/game/404.svg'

function PageNotFound() {
  return (
    <ErrorBoundaryUI
      title="404: Page not found"
      description="Sorry, we couldn't find that page."
      displayIcon={PageNotFoundIcon?.src}
      showRetryActionButton={false}
    />
  )
}

PageNotFound.getLayout = function getLayout(page: React.ReactNode) {
  return <>{page}</>
}

export default PageNotFound
